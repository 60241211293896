

































































































































import { EventBus } from '@/helpers/event-bus';
import { store } from '@/helpers/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  data() {
    return {
      store,
    };
  },
})
export default class Slider extends Vue {
  private emitVolumeChanged(): void {
    EventBus.$emit('volume-changed');
  }
}
